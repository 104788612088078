import(/* webpackMode: "eager" */ "/Users/exalogic/Projects/Exa_Farm/exa-farm/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/Users/exalogic/Projects/Exa_Farm/exa-farm/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/exalogic/Projects/Exa_Farm/exa-farm/lib/Providers.tsx");
;
import(/* webpackMode: "eager" */ "/Users/exalogic/Projects/Exa_Farm/exa-farm/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/Users/exalogic/Projects/Exa_Farm/exa-farm/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/Users/exalogic/Projects/Exa_Farm/exa-farm/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/Users/exalogic/Projects/Exa_Farm/exa-farm/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/Users/exalogic/Projects/Exa_Farm/exa-farm/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"]}],\"variableName\":\"poppins\"}");
